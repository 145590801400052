<template>
  <v-container fluid>
    <v-card outlined>
      <v-expansion-panels flat :value="myPanel" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header
            >{{ $t("searchPanel") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="3" sm="6">
                <v-combobox
                  v-model="filterModel.patientName"
                  :items="patients"
                  clearable
                  :label="$t('patients.patientName')"
                  item-text="patientDisplayName"
                  item-value="patientDisplayName"
                  :return-object="false"
                  hide-details
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-autocomplete
                  v-model="filterModel.patientCategory"
                  :items="categories"
                  clearable
                  :label="$t('patients.patientCategory')"
                  item-text="name"
                  item-value="guid"
                  :return-object="false"
                  hide-details
                  outlined
                  dense
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" sm="4">
                <v-select
                  v-model="filterModel.doctorGuid"
                  :items="doctors"
                  clearable
                  :label="$t('doctors.doctorName')"
                  item-text="doctorName"
                  item-value="guid"
                  :return-object="false"
                  hide-details
                  outlined
                  dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="2" sm="4">
                <v-select
                  :items="billLockFilter"
                  item-text="text"
                  item-value="value"
                  v-model="filterModel.billLock"
                  :label="$t('bills.billLock')"
                  hide-details
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2" sm="4">
                <v-select
                  :items="billPaidFilter"
                  item-text="text"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  v-model="filterModel.billPaid"
                  :label="$t('bills.billPaid')"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="2" sm="4">
                <v-dialog
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterModel.fromDate"
                      hide-details
                      outlined
                      dense
                      :label="$t('fromDate')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterModel.fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="2" sm="4">
                <v-dialog
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      outlined
                      dense
                      v-model="filterModel.toDate"
                      :label="$t('toDate')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterModel.toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col>
                <v-btn
                  elevation="0"
                  block
                  color="primary"
                  @click="refreshTable()"
                >
                  {{ $t("refresh") }}
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-row class="py-3">
      <v-col cols="2" align-self="center">
        <v-btn small @click="expandAll = !expandAll" class="mx-2">
          <v-icon color="primary">
            {{
              expandAll
                ? "mdi-arrow-collapse-vertical"
                : "mdi-arrow-expand-vertical"
            }}
          </v-icon>
        </v-btn>
      </v-col>

      <!-- <v-col align-self="end">
          <v-pagination
            v-if="pageLength"
            class="mb-2 "
            v-model="page"
            :length="pageLength ? pageLength : 0"
          ></v-pagination>
        </v-col>
        <v-col align-self="center" cols="2">
          <v-card>
            <v-select
              dense
              class="pa-1"
              solo
              flat
              :label="$t('total')"
              :items="itemPerPageSelect"
              item-text="text"
              item-value="value"
              v-model="itemsPerPage"
              hide-details=""
            >
            </v-select>
          </v-card>
        </v-col> -->
    </v-row>

    <v-expansion-panels
      :hover="false"
      flat
      focusable
      v-model="panel"
      multiple
      v-if="computedItems"
    >
      <v-expansion-panel v-for="(item, i) in computedItems" :key="i">
        <v-card outlined>
          <v-expansion-panel-header class="pa-1">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("bills.billCode") }} :
                      </span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue darken-1"
                            text
                            @click.stop
                            :to="'/billDetails/' + item.bill.guid"
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.bill.billCode }}
                          </v-btn>
                        </template>
                        <span>
                          {{ $t("patients.viewBillDetails") }}
                        </span>
                      </v-tooltip>
                    </td>
                    <td style="width: 210px">
                      <span class="grey--text"> {{ $t("date") }} : </span>
                      {{ item.bill.billDate | moment("YYYY/MM/DD") }}
                    </td>
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("patients.patientCode") }} :
                      </span>
                      {{ item.bill.patientCode }}
                    </td>
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("patients.patientName") }} :
                      </span>
                      {{ item.bill.patientName }}
                    </td>

                    <!-- <td style="width: 210px;">
                      {{ $t("bills.isPaid") }} :
                      {{ item.bill.isPaid }}
                    </td>
                    <td style="width: 210px;">
                      {{ $t("bills.isLock") }} :
                      {{ item.bill.isLock }}
                    </td> -->
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("procedures.totalAmount") }} :
                      </span>
                      {{ item.bill.totalAmount | number("0,0") }}
                    </td>
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("doctors.doctorName") }} :
                      </span>
                      {{ item.bill.doctorName }}
                    </td>
                    <td style="width: 210px">
                      <span class="grey--text">
                        {{ $t("bills.totalDiscount") }} :
                      </span>
                      {{ item.bill.totalDiscount | number("0,0") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-header>
          <v-divider />

          <v-expansion-panel-content v-if="item">
            <v-data-table
              :headers="headers"
              :items="item.bodies"
              :items-per-page="-1"
              class=""
              hide-default-footer
            >
              <template v-slot:[`item.dateReg`]="{ item }">
                {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
              </template>
              <template v-slot:[`item.totalAmount`]="{ item }">
                {{ item.totalAmount | number("0,0") }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount | number("0,0") }}
              </template>

              <template v-slot:[`item.doctorAmount`]="{ item }">
                {{ item.doctorAmount | number("0,0") }}
              </template>
              <template v-slot:[`item.doctorPercentage`]="{ item }">
                {{ item.doctorPercentage | number("0,0") }} %
              </template>
              <template v-slot:[`item.doctorIncome`]="{ item }">
                {{ item.doctorIncome | number("0,0") }}
              </template>
              <template v-slot:[`item.clinicIncome`]="{ item }">
                {{ item.clinicIncome | number("0,0") }}
              </template>

              <template v-slot:[`item.serviceGroup`]="{ item }">
                {{ getServiceGroup(item.serviceGroup) }}
              </template>

              <template slot="body.append">
                <tr
                  class="blue--text text--darken"
                  :class="
                    $vuetify.breakpoint.xsOnly
                      ? ' v-data-table__mobile-table-row'
                      : ''
                  "
                >
                  <td class="d-none d-sm-flex pa-3">
                    {{ $t("total") }}
                  </td>
                  <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
                  <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("bills.totalAmountFooter") }}
                    </span>
                    {{ sumTotals(item.bodies, "amount") | number("0,0") }}
                  </td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("bills.totalPaidFooter") }}
                    </span>
                    {{ sumTotals(item.bodies, "quantity") | number("0,0") }}
                  </td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("bills.totalDiscountFooter") }}
                    </span>
                    {{ sumTotals(item.bodies, "totalAmount") | number("0,0") }}
                  </td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("reports.doctorAmount") }}
                    </span>
                    {{ sumTotals(item.bodies, "doctorAmount") | number("0,0") }}
                  </td>
                  <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("reports.doctorIncome") }}
                    </span>
                    {{ sumTotals(item.bodies, "doctorIncome") | number("0,0") }}
                  </td>
                  <td
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <span class="hidden-sm-and-up">
                      {{ $t("reports.clinicIncome") }}
                    </span>
                    {{ sumTotals(item.bodies, "clinicIncome") | number("0,0") }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-card>
      </v-expansion-panel>

      <v-expansion-panel readonly>
        <v-card outlined>
          <v-expansion-panel-header class="pa-1">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr class="blue--text text--darken-1">
                    <td style="width: 210px">{{ $t("total") }}</td>
                    <td style="width: 210px"></td>
                    <td style="width: 210px"></td>
                    <td style="width: 210px"></td>

                    <td style="width: 210px">
                      {{ $t("procedures.totalAmount") }} :
                      {{ totalAmount | number("0,0") }}
                    </td>
                    <td style="width: 210px">
                      {{ $t("reports.totalDoctorIncome") }} :
                      {{ totalDoctorIncome | number("0,0") }}
                    </td>

                    <td style="width: 210px">
                      {{ $t("bills.totalDiscount") }} :
                      {{ totalDiscount | number("0,0") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-header>
        </v-card>

        <!-- <v-divider /> -->

        <!-- <v-expansion-panel-content>
        
          </v-expansion-panel-content> -->
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row class="py-3">
      <v-col cols="2" align-self="center"> </v-col>

      <v-col align-self="end">
        <v-pagination
          flat
          class="mb-2 elevation-0"
          v-model="page"
          :length="pageLength ? pageLength : 0"
        ></v-pagination>
      </v-col>
      <v-col align-self="center" cols="2">
        <v-card outlined>
          <v-select
            dense
            class="pa-1"
            solo
            flat
            :label="$t('total')"
            :items="itemPerPageSelect"
            item-text="text"
            item-value="value"
            v-model="itemsPerPage"
            hide-details=""
          >
          </v-select>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      filterModel: {
        patientName: "",
        doctorGuid: "",
        fromDate: "",
        toDate: "",
        billLock: 0,
        billPaid: 0,
      },
      expandAll: true,
      menu1: false,
      menu2: false,
      myPanel: [0],
      panel: [],
      items: [],
      categories: [],
      page: 1,
      headers: [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        { text: this.$t("services.serviceGroup"), value: "serviceGroup" },
        { text: this.$t("date"), value: "dateReg" },
        { text: this.$t("procedures.amount"), value: "amount" },
        { text: this.$t("procedures.quantity"), value: "quantity" },
        { text: this.$t("procedures.totalAmount"), value: "totalAmount" },
        { text: this.$t("reports.doctorAmount"), value: "doctorAmount" },
        {
          text: this.$t("services.doctorPercentage"),
          value: "doctorPercentage",
        },
        { text: this.$t("reports.doctorIncome"), value: "doctorIncome" },
        { text: this.$t("reports.clinicIncome"), value: "clinicIncome" },
      ],
      computedItems: [],
      pageLength: 1,
      itemsPerPage: 5,
      patients: [],
      doctors: [],

      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      billLockFilter: [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.locked"), value: 1 },
        { text: this.$t("bills.notLocked"), value: 2 },
      ],
      billPaidFilter: [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.paided"), value: 1 },
        { text: this.$t("bills.notPaided"), value: 2 },
      ],
    };
  },
  created() {
    this.itemsPerPage = 5;
    this.getPatient();
    this.getDoctors();
    this.getPatientCategories();
    this.filterModel.fromDate = this.$moment().format("YYYY-MM-DD");
    this.filterModel.toDate = this.$moment().format("YYYY-MM-DD");
  },
  watch: {
    page(val) {
      this.computedItems = [];
      var index = (val - 1) * this.itemsPerPage;

      for (var i = 1; i <= this.itemsPerPage; i++) {
        if (this.items[index + i])
          this.computedItems.push(this.items[index + i]);
      }
    },
    itemsPerPage(val) {
      var length = val;
      if (val == -1) {
        this.pageLength = null;
        this.page = 1;
        length = this.items.length;
      } else {
        var div = this.items.length / length;
        this.pageLength = Number.isInteger(div) ? div : (div | 0) + 1;
        if (this.page > this.pageLength) this.page = this.pageLength;
      }

      this.computedItems = [];
      var index = (this.page - 1) * length;

      for (var i = 0; i <= length; i++) {
        if (this.items[index + i])
          this.computedItems.push(this.items[index + i]);
      }
    },

    expandAll(val) {
      if (val) {
        for (var i = 0; i <= this.computedItems.length; i++) {
          this.panel.push(i);
        }
      } else {
        this.panel = [];
      }
    },
  },
  computed: {
    totalAmount() {
      if (this.items && this.items.length) {
        return this.items
          .map((item) => item.bill)
          .reduce((a, b) => a + (b["totalAmount"] || 0), 0);
      }
      return 0;
    },
    totalDoctorIncome() {
      if (this.items && this.items.length > 0) {
        // this.items
        //   .map((item) => item.bodies)
        //   .reduce((_, b) => b.reduce((c) => console.log(c)));

        var t = 0;
        for (var i = 0; i < this.items.length; i++) {
          var item = this.items[i];
          t += item.bodies.reduce((a, b) => a + (b["doctorIncome"] || 0), 0);
        }
        return t;
      }

      return 0;
    },
    totalDiscount() {
      if (this.items && this.items.length) {
        return this.items
          .map((item) => item.bill)
          .reduce((a, b) => a + (b["totalDiscount"] || 0), 0);
      }
      return 0;
    },
  },
  methods: {
    sumTotals(items, key) {
      return items.reduce((a, b) => a + (b[key] || 0), 0);
    },
    getServiceGroup(serviceGroup) {
      switch (serviceGroup) {
        case 0:
          return this.$t("services.serviceGroupType.general");
        case 1:
          return this.$t("services.serviceGroupType.dental");
        case 2:
          return this.$t("services.serviceGroupType.operation");
        case 3:
          return this.$t("services.serviceGroupType.test");
        default:
          return "";
      }
    },

    refreshTable() {
      this.computedItems = [];
      //console.log(this.isInRole(70));
      if (this.isInRole(70))
        this.$axios
          .get("Reports/BillReport?filter=" + JSON.stringify(this.filterModel))
          .then((response) => {
            this.items = response.data.data;
            if (response.data.data) {
              if (this.itemsPerPage == -1) {
                this.pageLength = 1;
                for (var i = 0; i <= response.data.data.length; i++) {
                  if (this.expandAll) this.panel.push(i);
                  if (i != this.itemsPerPage && this.items[i])
                    this.computedItems.push(this.items[i]);
                }
              } else {
                var div = response.data.data.length / this.itemsPerPage;
                this.pageLength = Number.isInteger(div) ? div : (div | 0) + 1;
                for (var x = 0; x <= this.itemsPerPage; x++) {
                  if (this.expandAll) this.panel.push(i);
                  if (x != this.itemsPerPage && this.items[x])
                    this.computedItems.push(this.items[x]);
                }
              }
            }
          });
    },

    getPatient() {
      if (this.isInRole("28")) {
        this.$axios.get("Patient").then((response) => {
          this.patients = response.data.data;
        });
      }
    },
    getDoctors() {
      this.$axios.get("Doctor/GetForBillReport").then((response) => {
        this.doctors = response.data.data;
      });
    },

    getPatientCategories() {
      this.$axios
        .get("Category")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
